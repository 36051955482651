import { t } from 'i18next';
import { FC, useCallback, useEffect } from 'react';
import { ModuleTemplate } from '../../models/Module';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import TranslatableInput from '../shared/form-control/TranslatableInput';

type CreateClassProps = {
  template: ModuleTemplate;
  onTemplateChange: (template: ModuleTemplate) => void;
};

const ManageClass: FC<CreateClassProps> = (props) => {
  const { template, onTemplateChange } = props;

  const addModuleValue = useCallback(
    <TKey extends keyof ModuleTemplate>(key: TKey, value: ModuleTemplate[TKey]) => {
      onTemplateChange({ ...template, [key]: value });
    },
    [template, onTemplateChange],
  );

  useEffect(() => {
    if (!template?.requiresValidation && template?.requiresValidationSignature) {
      addModuleValue('requiresValidationSignature', false);
    }
  }, [addModuleValue, template?.requiresValidation, template?.requiresValidationSignature]);

  useEffect(() => {
    if (!template?.requiresApproval && template?.requiresApprovalSignature) {
      addModuleValue('requiresApprovalSignature', false);
    }
  }, [addModuleValue, template?.requiresApproval, template?.requiresApprovalSignature]);

  return (
    <>
      <TranslatableInput
        data-cy="new-class-name"
        placeholder={t('organisation:modules.modals.create-class.name')}
        label={t('organisation:modules.modals.create-class.name')}
        aria-label={t('organisation:modules.modals.create-class.name')}
        translationKey="name"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={150}
        helpText={t('organisation:modules.modals.create-class.class-help')}
        autoFocus
      />
      <TranslatableInput
        data-cy="new-class-description"
        className="w-full resize-none"
        placeholder={t('organisation:modules.modals.create-class.description')}
        label={t('organisation:modules.modals.create-class.description')}
        aria-label={t('organisation:modules.modals.create-class.description')}
        translationKey="description"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={1000}
        inputType={'multiline'}
      />
      <TranslatableInput
        data-cy="new-class-prefix"
        placeholder={t('organisation:modules.modals.create-class.prefix')}
        label={t('organisation:modules.modals.create-class.prefix')}
        aria-label={t('organisation:modules.modals.create-class.prefix')}
        translationKey="prefix"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={150}
        helpText={t('organisation:modules.modals.create-class.prefix-help')}
      />

      <div className="my-4">
        <Checkbox
          labelBeforeCheckbox
          label={t('organisation:modules.modals.create-class.requires-validation')}
          description={t('organisation:modules.modals.create-class.requires-validation-description')}
          value={template?.requiresValidation}
          sliderSize={SliderSize.S}
          onChange={(selectedValue) => addModuleValue('requiresValidation', selectedValue)}
          slider
        />
        {template?.requiresValidation && (
          <Checkbox
            className="my-4"
            labelBeforeCheckbox
            value={template?.requiresValidationSignature}
            onChange={(requiresValidationSignature) => addModuleValue('requiresValidationSignature', requiresValidationSignature)}
            label={t('organisation:modules.modals.create-class.requires-validation-signature')}
          />
        )}

        <Checkbox
          labelBeforeCheckbox
          label={t('organisation:modules.modals.create-class.requires-approval')}
          description={t('organisation:modules.modals.create-class.requires-approval-description')}
          value={template?.requiresApproval}
          sliderSize={SliderSize.S}
          onChange={(selectedValue) => addModuleValue('requiresApproval', selectedValue)}
          slider
        />
        {template?.requiresApproval && (
          <Checkbox
            className="my-4"
            labelBeforeCheckbox
            value={template?.requiresApprovalSignature}
            onChange={(requiresApprovalSignature) => addModuleValue('requiresApprovalSignature', requiresApprovalSignature)}
            label={t('organisation:modules.modals.create-class.requires-approval-signature')}
          />
        )}
      </div>
    </>
  );
};

export default ManageClass;
